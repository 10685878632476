export const retina = `<g id="Canvas" transform="translate(311 -2475)">
<g class="focal-picker-retina-fill">
<use xlink:href="#path0_fill" transform="translate(-311 2475)" fill="#000000" fill-opacity="0.3"/>
<mask id="mask0_outline_ins">
<use xlink:href="#path0_fill" fill="white" transform="translate(-311 2475)"/>
</mask>
<g mask="url(#mask0_outline_ins)">
<use class="focal-picker-retina-ring" xlink:href="#path1_stroke_2x" transform="translate(-311 2475)" fill="#FFFFFF" fill-opacity="0.8"/>
</g>
</g>
</g>
<defs>
<path id="path0_fill" d="M 20 10C 20 15.5228 15.5228 20 10 20C 4.47715 20 0 15.5228 0 10C 0 4.47715 4.47715 0 10 0C 15.5228 0 20 4.47715 20 10Z"/>
<path id="path1_stroke_2x" d="M 18 10C 18 14.4183 14.4183 18 10 18L 10 22C 16.6274 22 22 16.6274 22 10L 18 10ZM 10 18C 5.58172 18 2 14.4183 2 10L -2 10C -2 16.6274 3.37258 22 10 22L 10 18ZM 2 10C 2 5.58172 5.58172 2 10 2L 10 -2C 3.37258 -2 -2 3.37258 -2 10L 2 10ZM 10 2C 14.4183 2 18 5.58172 18 10L 22 10C 22 3.37258 16.6274 -2 10 -2L 10 2Z"/>
</defs>`
